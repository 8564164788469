import SbUtils from '../../../../Utils/SbUtils'
import { useState } from 'react';

const Caption = ({ feedSettings, post, postIndex, isFeedEditor }) => {
    const [toggleCaption, setToggleCaption] = useState(false);

    const text = post?.video_description;
    const contentLength = feedSettings?.contentLength || 100;
    const shortCaption = text?.substring(0, contentLength);

    return (
        <div className='sb-post-item-caption sb-relative'>
            {toggleCaption ? text : shortCaption}

            {SbUtils.checkNotEmpty(text) && text.length > contentLength &&
                <a className='sb-post-readmorelink'
                    onClick={() => setToggleCaption(!toggleCaption)}
                >
                    ...
                </a>
            }

            {isFeedEditor && SbUtils.addHighlighter('post-caption', 'Caption', postIndex)}
        </div>
    )
}

export default Caption;
