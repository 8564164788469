import { LikesIcon } from "../../../../Utils/icons";

const Likes = ({ post, fillColor }) => {
    return (
        <span className='sb-post-item-meta-likes'>
            <LikesIcon fillColor={fillColor} />
            {post?.like_count}
        </span>
    );
}

export default Likes;