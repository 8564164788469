import React, { useEffect, useState } from "react";

const VideoComponent = ({ post }) => {
  const [currentPost, setCurrentPost] = useState(post);

  useEffect(() => {
      setCurrentPost(post);
  }, [post]);

  if(currentPost.id !== post.id) {
    return "";
  }

  return (
    <video autoPlay={true} loop width={"100%"} height={"100%"} controls style={{
      height: "80vh",
    }}>
      <source src={currentPost?.video_url ?? ""} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoComponent;
