import { PlayIcon } from "../../../../Utils/icons";

const Play = (props) => {
    const {className} = props;

    return (
        <div className={`sb-post-item-play ${className}`}>
            <PlayIcon />
        </div>
    )
}

export default Play;