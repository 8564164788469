import React, { useEffect, useState } from 'react'
import SbUtils from '../../../Utils/SbUtils'
import Button from '../../Common/Button'
import './LoadMoreButton.css'

const LoadMoreButton = (props) => {

    const { feedSettings, feedId, isFeedEditor, posts, setPosts, postsList, setPostsList, postsPerPage } = props;
    const layout = feedSettings?.layout || 'grid';

    const [page, setPage] = useState(1);
    const [loadCount, setLoadCount] = useState(1);
    const [offset, setOffset] = useState(postsPerPage);
    const [nextPage, setNextPage] = useState(props?.nextPage || null);
    const [showLoadButton, setShowLoadButton] = useState(props?.nextPage || null);

    useEffect(() => {
        if (!isFeedEditor) {
            setOffset(postsPerPage);
            setLoadCount(1);
            setPostsList(posts?.slice(0, postsPerPage));
        }

    }, [postsPerPage]);

    const loadMorePosts = async () => {

        if (posts?.length >= postsPerPage * (loadCount + 1)) {
            setPostsList(prevPosts => [...prevPosts, ...posts.slice(offset, offset + postsPerPage)]);
        }

        else {
            const pageNumber = page + 1;
            setPage(pageNumber);

            const newPosts = await SbUtils.loadMorePosts(feedId, pageNumber);

            if (newPosts?.nextPage) {
                setNextPage(newPosts.nextPage);
            } else {
                setNextPage(null);
            }
            setPosts(prevPosts => [...prevPosts, ...newPosts?.posts]);
        }

        setOffset(offset + postsPerPage);
        setLoadCount(loadCount + 1);
    }

    useEffect(() => {
        if (posts && posts?.length > 0) {
            const newPostsList = [...posts.slice(0, loadCount * postsPerPage)];
            setPostsList(newPostsList);
        }

    }, [posts]);

    useEffect(() => {
        if (posts?.length > postsList?.length) {
            return setShowLoadButton(true);
        }
        else {
            return setShowLoadButton(false)
        }

    }, [posts, postsList]);

    return (
        feedSettings?.showLoadButton === true &&
        layout !== 'carousel' &&
        (isFeedEditor || nextPage || showLoadButton) &&
        <section className='sb-load-button-ctn sb-fs'>
            <Button
                text={feedSettings?.loadButtonText}
                full-width={true}
                customClass='sb-load-button'
                size='small'
                onClick={!isFeedEditor ? loadMorePosts : null}
            />
            {isFeedEditor && SbUtils.addHighlighter('loadmore-button', 'Load More Button')}
        </section>
    )
}

export default LoadMoreButton;