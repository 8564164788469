import { ViewsIcon } from "../../../../Utils/icons";

const Views = ({ post, fillColor }) => {
    return (
        <span className='sb-post-item-meta-views'>
            <ViewsIcon fillColor={fillColor} />
            {post?.view_count}
        </span>
    );
}

export default Views;