import React, { useEffect, useState } from 'react';
import { default as FeedHeader } from '../Components/TikTokPlugin/Feed/Header'
import PostsWrapper from '../Components/TikTokPlugin/Feed/PostsWrapper'
import LoadMoreButton from '../Components/TikTokPlugin/Feed/LoadMoreButton'

const Feed = (props) => {
    const sbttFeedOptions = window.sbtt_feed_options;
    const { feedSettings, feedId, headerData, nextPage } = props;
    const isPro = sbttFeedOptions?.isPro || false;

    const [posts, setPosts] = useState(props?.posts || []);
    const [postsPerPage, setPostsPerPage] = useState(feedSettings?.numPostDesktop || 10);
    const [postsList, setPostsList] = useState([]);

    useEffect(() => {
        checkWindowWidth();

        const handleResize = () => {
            checkWindowWidth();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(posts?.length > 0) {
            setPostsList(posts?.slice(0, postsPerPage));
        }
    }, [postsPerPage]);

    const checkWindowWidth = () => {
        const newWindowWidth = window.innerWidth;
        let perPage = 0;

        if (newWindowWidth <= 480) {
            // Mobile device
            perPage = feedSettings?.numPostMobile || 6;
        } else if (newWindowWidth <= 640) {
            // Tablet
            perPage = feedSettings?.numPostTablet || 8;
        } else {
            // Desktop (or wider)
            perPage = feedSettings?.numPostDesktop || 10;
        }

        setPostsPerPage(perPage);
    }

    return (
        <section
            className='sb-feed-wrapper sb-fs'
            id={"sb-tiktok-feeds-container-" + feedId}
        >
            <section
                className='sb-feed-container sb-fs'
                data-layout={feedSettings.layout}
                data-feed-template={feedSettings.feedTemplate}
                data-post-style={feedSettings.postStyle}
                data-plugin={isPro ? 'pro' : 'free'}
            >

                <FeedHeader
                    feedSettings={feedSettings}
                    headerData={headerData}
                    isPro={isPro}
                    isFeedEditor={false}
                />
                <PostsWrapper
                    feedSettings={feedSettings}
                    headerData={headerData}
                    posts={postsList}
                    isPro={isPro}
                    isFeedEditor={false}
                />
                {
                    isPro &&
                    <LoadMoreButton
                        feedSettings={feedSettings}
                        feedId={feedId}
                        isFeedEditor={false}
                        posts={posts}
                        setPosts={setPosts}
                        postsList={postsList}
                        setPostsList={setPostsList}
                        postsPerPage={postsPerPage}
                        nextPage={nextPage}
                    />
                }

            </section>
        </section>
    );
};
export default Feed;