import SbUtils from '../../../Utils/SbUtils'
import { useState } from 'react';

import './SinglePost.css'
import Caption from './Elements/Caption'
import Likes from './Elements/Likes';
import Views from './Elements/Views';
import Play from './Elements/Play';

const SinglePost = ({ post, headerData, postIndex, feedSettings, isPro, isFeedEditor, handleModalOpen }) => {
    let layout = feedSettings?.layout,
        template = feedSettings?.feedTemplate,
        numberOfColumns = parseInt(feedSettings?.carouselDesktopColumns);

    // exceptions
    const carousel_templates = ['carousel', 'showcase_carousel'];
    layout = (carousel_templates.includes(template) && layout === 'carousel') && numberOfColumns === 1 ? 'list' : layout;

    const layoutClass = layout === 'list' ? 'sb-post-list-item' : 'sb-post-block-item';
    const thumbnailSize = layout === 'list' ? 'large' : 'medium';
    const postStyle = feedSettings?.postStyle || 'regular';
    const playerExperience = feedSettings?.playerExperience || 'lightbox';
    const noMetaClass = isPro && (feedSettings?.postElements?.includes('views') || feedSettings?.postElements?.includes('likes')) ? '' : 'sb-no-meta';

    const [toggleVideo, setToggleVideo] = useState(false);

    return (
        <div className='sb-post-item-wrap' key={postIndex}>
            <div className={`sb-post-item ${layoutClass}`} key={postIndex}>
                {
                    isPro && feedSettings?.postElements?.includes('author_info') && layout !== 'list' &&
                    <div className='sb-post-item-author'>
                        <img
                            src={headerData?.local_avatar_url || headerData?.avatar_url_100}
                            alt="profile"
                            className='sb-post-item-author-image'
                        />

                        <p className='sb-post-item-author-name'>
                            @{headerData?.username}
                        </p>
                    </div>
                }
                {
                    feedSettings?.postElements?.includes('thumbnail') &&
                    <div className='sb-post-item-image-wrap'
                        style={{
                            position: 'relative',
                            width: thumbnailSize === 'large' ? "385px" : "100%",
                            height: thumbnailSize === 'large' ? "380px" : "269px",
                            overflow: 'hidden',
                            borderRadius: postStyle === "regular" ? '8px' : '0px',
                        }}
                    >

                        {toggleVideo && <iframe
                            src={`https://www.tiktok.com/embed/v2/${post?.id}`}
                            title={post?.video_description || 'TikTok Video'}
                            height={'100%'}
                            width={'100%'}
                            style={{
                                margin: '0 auto',
                                border: 'none',
                            }}
                            allowtransparency="true"
                        />
                        }

                        {!toggleVideo &&
                            <>
                                <img className='sb-post-item-image'
                                    src={post?.local_cover_image_url || post?.cover_image_url}
                                    onClick={() => {
                                        if (!isFeedEditor) {
                                            playerExperience === "lightbox" ? handleModalOpen(postIndex) : setToggleVideo(true)
                                        }
                                    }}
                                />

                                {layout !== 'list' &&
                                    <>
                                        {
                                            feedSettings?.postElements?.includes('playIcon') &&
                                            <Play className={noMetaClass} />
                                        }

                                        <div className='sb-post-item-meta'>
                                            {
                                                isPro && feedSettings?.postElements?.includes('views') &&
                                                <Views post={post} />
                                            }

                                            {
                                                isPro && feedSettings?.postElements?.includes('likes') &&
                                                <Likes post={post} />
                                            }
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                }

                <div className='sb-post-item-details'>
                    {
                        isPro && feedSettings?.postElements?.includes('author_info') && layout === 'list' &&
                        <div className='sb-post-item-author'>
                            <img
                                src={headerData?.local_avatar_url || headerData?.avatar_url_100}
                                alt="profile"
                                className='sb-post-item-author-image'
                            />

                            <p className='sb-post-item-author-name'>
                                @{headerData?.username}
                            </p>
                        </div>
                    }

                    {
                        isPro && feedSettings?.postElements?.includes('caption') &&
                        <Caption
                            post={post}
                            postIndex={postIndex}
                            feedSettings={feedSettings}
                            isFeedEditor={isFeedEditor}
                        />
                    }

                    {layout === 'list' &&
                        <div className='sb-post-item-meta'>
                            {
                                isPro && feedSettings?.postElements?.includes('views') &&
                                <Views post={post} fillColor={'black'} />
                            }

                            {
                                isPro && feedSettings?.postElements?.includes('likes') &&
                                <Likes post={post} fillColor={'black'} />
                            }
                        </div>
                    }

                </div>
            </div>
            {isFeedEditor && SbUtils.addHighlighter('tiktokfeed', 'Posts', postIndex)}
        </div>
    )
}

export default SinglePost;