const PlayIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.38254C0 0.861336 1.63093 -0.102987 2.96384 0.630111L16.8138 8.24757C18.1953 9.00742 18.1953 10.9926 16.8138 11.7524L2.96384 19.3699C1.63093 20.103 0 19.1387 0 17.6175V2.38254Z" fill="white" />
        </svg>
    );
};

const ViewsIcon = (props) => {
    const fillColor = props.fillColor ? props.fillColor : 'white';
    const opacity = fillColor === 'white' ? '0.9' : '0.6';

    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity={opacity}>
                <path d="M7.99996 6.5C7.46953 6.5 6.96082 6.71071 6.58575 7.08579C6.21067 7.46086 5.99996 7.96957 5.99996 8.5C5.99996 9.03043 6.21067 9.53914 6.58575 9.91421C6.96082 10.2893 7.46953 10.5 7.99996 10.5C8.53039 10.5 9.0391 10.2893 9.41417 9.91421C9.78925 9.53914 9.99996 9.03043 9.99996 8.5C9.99996 7.96957 9.78925 7.46086 9.41417 7.08579C9.0391 6.71071 8.53039 6.5 7.99996 6.5ZM7.99996 11.8333C7.1159 11.8333 6.26806 11.4821 5.64294 10.857C5.01782 10.2319 4.66663 9.38406 4.66663 8.5C4.66663 7.61595 5.01782 6.7681 5.64294 6.14298C6.26806 5.51786 7.1159 5.16667 7.99996 5.16667C8.88401 5.16667 9.73186 5.51786 10.357 6.14298C10.9821 6.7681 11.3333 7.61595 11.3333 8.5C11.3333 9.38406 10.9821 10.2319 10.357 10.857C9.73186 11.4821 8.88401 11.8333 7.99996 11.8333ZM7.99996 3.5C4.66663 3.5 1.81996 5.57333 0.666626 8.5C1.81996 11.4267 4.66663 13.5 7.99996 13.5C11.3333 13.5 14.18 11.4267 15.3333 8.5C14.18 5.57333 11.3333 3.5 7.99996 3.5Z" fill={fillColor} />
            </g>
        </svg>
    );
}

const LikesIcon = (props) => {
    const fillColor = props.fillColor ? props.fillColor : 'white';
    const opacity = fillColor === 'white' ? '0.9' : '0.6';

    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity={opacity} d="M7.1123 12.6313C6.92822 12.6313 6.66162 12.4854 6.42676 12.333C2.89746 10.0479 0.618652 7.35645 0.618652 4.63965C0.618652 2.24023 2.28174 0.589844 4.33203 0.589844C5.60791 0.589844 6.55371 1.30078 7.1123 2.33545C7.67725 1.29443 8.62305 0.589844 9.89893 0.589844C11.9429 0.589844 13.606 2.24023 13.606 4.63965C13.606 7.35645 11.3271 10.0479 7.8042 12.333C7.56934 12.4854 7.29639 12.6313 7.1123 12.6313Z" fill={fillColor} />
        </svg>
    );
}

const TikTokIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3626 4.78024C12.2749 4.73489 12.1895 4.68518 12.1067 4.63129C11.866 4.47217 11.6453 4.28468 11.4494 4.07285C10.9592 3.51199 10.7762 2.94299 10.7087 2.54462H10.7114C10.6551 2.21395 10.6784 2 10.6819 2H8.44929V10.6332C8.44929 10.7491 8.44929 10.8637 8.44441 10.9769C8.44441 10.9909 8.44306 11.0039 8.44225 11.0191C8.44225 11.0253 8.44225 11.0318 8.44089 11.0383C8.44089 11.04 8.44089 11.0416 8.44089 11.0432C8.41736 11.353 8.31806 11.6522 8.15174 11.9146C7.98542 12.177 7.75717 12.3944 7.48707 12.5479C7.20556 12.708 6.88717 12.792 6.5633 12.7916C5.52308 12.7916 4.68002 11.9434 4.68002 10.8959C4.68002 9.84836 5.52308 9.00015 6.5633 9.00015C6.76021 8.99997 6.9559 9.03095 7.14313 9.09196L7.14583 6.8187C6.57748 6.74528 6.00008 6.79045 5.45006 6.95136C4.90004 7.11226 4.38933 7.38541 3.95016 7.75357C3.56534 8.08792 3.24182 8.48687 2.99417 8.93245C2.89992 9.09494 2.54433 9.74788 2.50127 10.8076C2.47419 11.4091 2.65483 12.0322 2.74095 12.2898V12.2952C2.79511 12.4469 3.005 12.9644 3.34704 13.4007C3.62286 13.7507 3.94872 14.0581 4.31414 14.3131V14.3077L4.31956 14.3131C5.4004 15.0476 6.59878 14.9994 6.59878 14.9994C6.80623 14.991 7.50115 14.9994 8.29032 14.6253C9.16561 14.2107 9.66392 13.593 9.66392 13.593C9.98226 13.2239 10.2354 12.8032 10.4125 12.3491C10.6145 11.818 10.6819 11.1811 10.6819 10.9265V6.34639C10.709 6.36264 11.0697 6.60123 11.0697 6.60123C11.0697 6.60123 11.5894 6.93434 12.4003 7.15126C12.982 7.30563 13.7658 7.33813 13.7658 7.33813V5.12174C13.4911 5.15153 12.9335 5.06487 12.3626 4.78024Z" fill="#696D80" />
        </svg>
    );
}

const PreviousIcon = ({
    style = {}, className = '',
}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>

            <g >
                <path d="M10 16.6673L3.33334 10.0007L10 3.33398L11.1875 4.50065L6.52084 9.16732H16.6667V10.834H6.52084L11.1875 15.5007L10 16.6673Z" fill="#2C324C" />
            </g>
        </svg>
    )
};

const NextIcon = ({
    style = {}, className = '',
}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>

            <g>
                <path d="M10 16.6673L8.81254 15.5007L13.4792 10.834H3.33337V9.16732H13.4792L8.81254 4.50065L10 3.33398L16.6667 10.0007L10 16.6673Z" fill="#2C324C" />
            </g>
        </svg>
    )
}

const CloseIcon = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 6.41L18.09 5L12.5 10.59L6.91 5L5.5 6.41L11.09 12L5.5 17.59L6.91 19L12.5 13.41L18.09 19L19.5 17.59L13.91 12L19.5 6.41Z" fill="#434960" />
        </svg>
    );
};

export { NextIcon, PlayIcon, ViewsIcon, LikesIcon, TikTokIcon, PreviousIcon, CloseIcon };