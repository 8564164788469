import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import SinglePost from './SinglePost'
import LightboxModal from './Elements/Modal';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const MasonryLayout = ({ feedSettings, posts, headerData, isPro, editorActiveDevice, isFeedEditor }) => {
    const [activePost, setActivePost] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isLightboxVisible, setIsLightboxVisible] = useState(false);

    let desktopNumber = feedSettings.masonryDesktopColumns;

    if (isFeedEditor) {
        switch (editorActiveDevice.device) {
            case 'mobile':
                desktopNumber = parseInt(feedSettings.masonryMobileColumns);
                break;
            case 'tablet':
                desktopNumber = parseInt(feedSettings.masonryTabletColumns);
                break;
            default:
                desktopNumber = parseInt(feedSettings.masonryDesktopColumns);
                break;
        }
    }

    const masonryBreakPoint = {
        350: parseInt(feedSettings.masonryMobileColumns),
        750: parseInt(feedSettings.masonryTabletColumns),
        900: desktopNumber
    };

    //
    const handleModalOpen = (postIndex) => {
        setActivePost(posts[postIndex]);
        setActiveIndex(postIndex);
        setIsLightboxVisible(true);
    }

    //
    const handleCloseModal = () => {
        setActivePost(null);
        setIsLightboxVisible(false);
        document.body.classList.remove('sbt-lightbox-modal-open');
    }

    //
    const handlePreviousItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === 0) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex - 1]);
            setActiveIndex(currentPostIndex - 1);
        }
    }

    //
    const handleNextItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === posts.length - 1) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex + 1]);
            setActiveIndex(currentPostIndex + 1);
        }
    }


    return (
        <>
            <ResponsiveMasonry
                columnsCountBreakPoints={masonryBreakPoint}
            >
                <Masonry
                    gutter={`${feedSettings.horizontalSpacing}px`}
                >
                    {
                        posts.map((post, postIndex) =>
                            <SinglePost
                                post={post}
                                headerData={headerData}
                                postIndex={postIndex}
                                feedSettings={feedSettings}
                                key={postIndex}
                                isPro={isPro}
                                isFeedEditor={isFeedEditor}
                                handleModalOpen={handleModalOpen}
                            />
                        )
                    }
                </Masonry>
            </ResponsiveMasonry>

            {
                isLightboxVisible && activePost &&
                createPortal(
                    <LightboxModal closeModal={handleCloseModal}
                        post={activePost}
                        headerData={headerData}
                        feedSettings={feedSettings}
                        handleNext={handleNextItem}
                        handlePrev={handlePreviousItem}
                        showNext={
                            !(activeIndex === posts.length - 1)
                        }
                        showPrev={
                            !(activeIndex === 0)
                        }
                        isPro={isPro}
                        isLightboxVisible={isLightboxVisible}
                        setIsLightboxVisible={setIsLightboxVisible}
                    />,
                    document.body
                )
            }
        </>
    )
}

export default MasonryLayout;