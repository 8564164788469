import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import SinglePost from './SinglePost'
import LightboxModal from './Elements/Modal';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const CarouselLayout = ({ feedSettings, headerData, posts, isPro, editorActiveDevice, isFeedEditor }) => {

    const [activePost, setActivePost] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isLightboxVisible, setIsLightboxVisible] = useState(false);

    let desktopNumber = feedSettings.carouselDesktopColumns;

    if (isFeedEditor) {
        switch (editorActiveDevice.device) {
            case 'mobile':
                desktopNumber = feedSettings.carouselMobileColumns;
                break;
            case 'tablet':
                desktopNumber = feedSettings.carouselTabletColumns;
                break;
            default:
                desktopNumber = feedSettings.carouselDesktopColumns;
                break;
        }
    }

    let responsiveLayoutData = {
        0: {
            items: feedSettings.carouselMobileColumns,
            rows: feedSettings.carouselMobileRows
        },
        600: {
            items: feedSettings.carouselTabletColumns,
            rows: feedSettings.carouselTabletRows
        },
        1024: {
            items: desktopNumber,
            rows: feedSettings.carouselDesktopRows
        }
    };

    //
    const handleModalOpen = (postIndex) => {
        setActivePost(posts[postIndex]);
        setActiveIndex(postIndex);
        setIsLightboxVisible(true);
    }

    //
    const handleCloseModal = () => {
        setActivePost(null);
        setIsLightboxVisible(false);
        document.body.classList.remove('sbt-lightbox-modal-open');
    }

    //
    const handlePreviousItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === 0) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex - 1]);
            setActiveIndex(currentPostIndex - 1);
        }
    }

    //
    const handleNextItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === posts.length - 1) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex + 1]);
            setActiveIndex(currentPostIndex + 1);
        }
    }

    return (
        <>
            <OwlCarousel
                responsive={responsiveLayoutData}
                margin={parseInt(feedSettings.horizontalSpacing)}
                loop={feedSettings.carouselLoopType === 'infinity'}
                rewind={feedSettings.carouselLoopType === 'rewind'}
                nav={feedSettings.carouselShowArrows}
                dots={feedSettings.carouselShowPagination}
                autoplay={feedSettings.carouselEnableAutoplay}
                autoplayTimeout={feedSettings.carouselIntervalTime}
            >
                {
                    posts.map((post, postIndex) =>
                        <SinglePost
                            headerData={headerData}
                            post={post}
                            postIndex={postIndex}
                            feedSettings={feedSettings}
                            key={postIndex}
                            isPro={isPro}
                            isFeedEditor={isFeedEditor}
                            handleModalOpen={handleModalOpen}
                        />
                    )
                }
            </OwlCarousel>

            {
                isLightboxVisible && activePost &&
                createPortal(
                    <LightboxModal closeModal={handleCloseModal}
                        post={activePost}
                        headerData={headerData}
                        feedSettings={feedSettings}
                        handleNext={handleNextItem}
                        handlePrev={handlePreviousItem}
                        showNext={
                            !(activeIndex === posts.length - 1)
                        }
                        showPrev={
                            !(activeIndex === 0)
                        }
                        isPro={isPro}
                        isLightboxVisible={isLightboxVisible}
                        setIsLightboxVisible={setIsLightboxVisible}
                    />,
                    document.body
                )
            }
        </>
    )
}

export default CarouselLayout;