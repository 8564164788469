import SbUtils from '../../Utils/SbUtils'

const Button = ( props ) => {

    const   slug = 'sb-btn',
            classesList =  [ 'type', 'size' ],
            actionsList =  [ 'onClick' ],
            attributesList = [
                { 'icon-position' : 'left'},
                'full-width',
                'boxshadow',
                'disabled',
                'loading'
            ],
            stylesList = [
                'fontSize',
                'backgroundColor',
            ];

    return (
        <button
            className={ SbUtils.getClassNames( props, slug, classesList ) }
           { ...SbUtils.getElementActions( props, actionsList ) }
           { ...SbUtils.getElementAttributes( props, attributesList ) }
           data-onlyicon={ props.text !== undefined }
           style={ SbUtils.getElementStyles( props, stylesList ) }
        >
            { SbUtils.printIcon( props.icon, 'sb-btn-icon', false, props?.iconSize ) } { props.text }
            {
                props?.tooltip !== undefined &&
                SbUtils.printTooltip( props.tooltip, {
                    type : props?.tooltipType,
                    position : props?.tooltipPosition
                } )
            }
        </button>
    )
}

export default Button;